import { Switch, SwitchProps } from 'antd';

import { Toast } from '@/models';
import { createNotification } from '@/utils';
import useLocale from '@/hooks/useLocale';
import { useState } from 'react';

type ActiveSwitchProps = Omit<SwitchProps, 'onChange'> & {
  onChange: (checked: boolean) => Promise<void>;
  locale?: { Yes?: string; No?: string };
};

const ActiveSwitch = ({ checked, onChange, locale: customLocale, ...SwitchProps }: ActiveSwitchProps): React.JSX.Element => {
  const [isChecked, setIsChecked] = useState(!!checked);
  const [loading, setLoading] = useState(false);
  const locale = useLocale(customLocale);

  const handleChange = async (isChecked: boolean) => {
    try {
      setLoading(true);
      setIsChecked(isChecked);
      await onChange(isChecked);
    } catch (err) {
      createNotification('Error', Toast.Type.DANGER, err?.message || err);
      setIsChecked(!isChecked);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Switch
      {...SwitchProps}
      checked={isChecked}
      onChange={handleChange}
      loading={loading}
      disabled={loading}
      checkedChildren={locale('Yes')}
      unCheckedChildren={locale('No')}
    />
  );
};

export default ActiveSwitch;
