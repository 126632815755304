import './styles.scss';

import Field, { FieldProps } from '@/components/Field';
import Input, { InputProps } from '@/components/Input';
import React, { ReactNode, useMemo } from 'react';

import { InputGroup } from 'react-bootstrap';

export type NumberInputProps = Omit<InputProps, 'value' | 'onChange'> & {
  value: number;
  onChange: (value: number) => void;
  //TODO: change styles to work without needing the append prop
  append?: React.ReactNode;
  min?: number;
  max?: number;
};
const NumberInput = ({ value, onChange, append, min, max, ...props }: NumberInputProps): ReactNode => {
  const parsedValue = useMemo((): number => (!isNaN(parseFloat(`${value}`)) ? parseFloat(`${value}`) : undefined), [value]);
  const handleChange = (val: string): void => {
    val.replace(/[^0-9.-]/g, '').replace(/([0-9.-]{1,})-/g, '$1');
    const result = val === '' ? undefined : !isNaN(parseFloat(val)) ? parseFloat(val) : parsedValue;
    if (min !== undefined && result < min) return onChange(min);
    if (max !== undefined && result > max) return onChange(max);
    onChange(result);
  };

  return (
    <InputGroup className="NumberInput">
      <Input {...props} value={parsedValue ?? ''} onChange={handleChange} />
      <div className="NumberInput-Controls">
        <button onClick={(): void => handleChange(`${(parsedValue || 0) + 1}`)} tabIndex={-1}>
          <i className="fa fa-angle-up" />
        </button>
        <button onClick={(): void => handleChange(`${(parsedValue || 0) - 1}`)} tabIndex={-1}>
          <i className="fa fa-angle-down" />
        </button>
      </div>
      {append && <div className="NumberInput-Append">{append}</div>}
    </InputGroup>
  );
};

// TODO: Add a NumberInputField named export that wraps the NumberInput in a Field component.
export type NumberInputFieldProps = NumberInputProps & Omit<FieldProps, 'children'>;
export const NumberInputField = ({ label, feedback, valid, required, ...inputProps }: NumberInputFieldProps): ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required}>
      <NumberInput {...inputProps} isValid={valid === true} isInvalid={valid === false} />
    </Field>
  );
};

const MAX_LENGTH = 12;
const CurrencyInput = ({ value, onChange, ...props }: NumberInputProps) => {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    // Allow navigation keys: Arrow keys, Home, End, Tab
    if (['ArrowLeft', 'ArrowRight', 'Home', 'End', 'Tab'].includes(event.key)) return;

    event.preventDefault();

    if (event.key === 'Delete') return onChange(0);
    if (event.key === 'Backspace') return onChange(Math.max(Math.floor(value / 10) / 100, 0));
    if (`${value}`.length >= (props?.max || MAX_LENGTH)) return;
    if (event.key >= '0' && event.key <= '9') return onChange(Math.max((value * 10 + parseInt(event.key)) / 100, 0));
  };

  const formatCurrency = (amountInCents: number) => {
    const centsStr: string = amountInCents.toString();

    if (centsStr.length <= 2) return '0.' + centsStr.padStart(2, '0');
    const cents: string = centsStr.slice(-2);
    const dollars: string = centsStr.slice(0, -2);
    const formattedDollars: string = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedDollars + '.' + cents;
  };

  return <Input type="text" value={formatCurrency(value)} onChange={() => null} onKeyDown={handleKeyDown} {...props} />;
};

const CurencyInputField = ({ label, feedback, valid, required, ...inputProps }: NumberInputFieldProps): ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required}>
      <CurrencyInput {...inputProps} isValid={valid === true} isInvalid={valid === false} />
    </Field>
  );
};

NumberInput.Currency = CurrencyInput;
NumberInputField.Currency = CurencyInputField;

export default NumberInput;
