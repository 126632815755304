import { ConnectionDetails, createNotification } from '@/utils/custom';
import { Provider, ProviderSearch, SortDirectionEnum } from '@/models/gen/graphql';
import { QueryInputType, onEnter, parsePhoneNumber, queryInput } from '@/utils';
import { SEARCH_PROVIDERS_PAGE_SIZE, useSearchClientsTable } from '@/api/services/providers/searchProviders';
import VirtualTable, { DynamicCell, SelectCell, VirtualTableRow, useVirtualTable } from '@/components/VirtualTable';
import { useMemo, useRef, useState } from 'react';

import ActiveSwitch from '@/components/ActiveSwitch';
import { Badge } from 'react-bootstrap';
import ConfirmationButton from '@/components/ConfirmationButton';
import EditClientsModal from '@/components/EditClientsModal';
import Filters from '@/components/Filters';
import FormButton from '@/components/FormButton';
import FormField from '@/components/FormField';
import HasPermission from '@/components/HasPermission';
import PageInfo from '@/components/PageInfo';
import Toast from '@/models/Toast';
import deleteClientBulk from '@/api/services/providers/deleteClientBulk';
import { getClasses } from '@/utils/strings';
import { stringify } from '@/utils/objects';
import { updateClientActivationBulk } from '@/api/services/providers/updateClientBulk';
import useHotkeys from '@/hooks/useHotkeys';

type ClientTableState = {
  selected: string[];
  editing: boolean;
  creating: boolean;
  search: string;
  sorting: { column: string; direction: string };
};

const initClientsTableState: ClientTableState = {
  selected: [],
  editing: false,
  creating: false,
  search: '',
  sorting: { column: undefined, direction: undefined },
};
const ClientsTable = (): JSX.Element => {
  // Init State
  const [state, setState] = useState(initClientsTableState);
  const { editing, creating, selected, search, sorting } = state;

  // HotKeys
  const searchbarRef = useRef(null);
  useHotkeys('clients', {
    'shift+ctrl+s': {
      name: 'Focus Search Bar',
      description: 'Focuses the Search Bar',
      action: (): any => searchbarRef.current.focus(),
    },
    'shift+ctrl+c': {
      name: 'Toggle Create Trip',
      description: 'Opens and closes the Create Trip modal',
      action: (): any =>
        HasPermission.check('allowCreateClient')
          ? setState((current: ClientTableState): ClientTableState => ({ ...current, creating: !current?.creating, editing: false }))
          : undefined,
    },
    'shift+ctrl+e': {
      name: 'Toggle Edit Trip(s)',
      description: 'Opens and closes the Edit Trip modal',
      action: (): void => {
        if (selected?.length > 0) {
          setState((current: ClientTableState): ClientTableState => ({ ...current, creating: false, editing: !current?.editing }));
        }
      },
    },
  });

  // Init GraphApi Queries
  const [{ data, loading }, { fetch: searchClients, refetch, fetchMore }] = useSearchClientsTable();
  const { rows = [], hasNextPage = false, totalCount = 0 } = data || {};

  const { onSelect, makeSortable, filteredRows } = useVirtualTable(setState, { selected, sorting, rows, search });

  // State Handlers
  const onHide = async (): Promise<any> => {
    setState((current: ClientTableState): ClientTableState => ({ ...current, editing: false, creating: false, selected: [] }));
    await refetch();
  };
  const lastQuery = useRef(null);
  const handleSearch = async (): Promise<void> => {
    const query: ProviderSearch = {
      createdAt: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Desc, 1),
    };
    await (stringify.compare(lastQuery.current, query) ? refetch() : searchClients([query]));
    lastQuery.current = query;
  };

  const getMore = async (after: number): Promise<ConnectionDetails<Provider>> => {
    if (loading || !hasNextPage) return;
    const result = await fetchMore([lastQuery.current], {
      page: Math.round(after / SEARCH_PROVIDERS_PAGE_SIZE),
      merge: true,
    });
    return result;
  };

  const ActiveCell = useMemo(() => {
    const ActiveCellComponent = ({ data }: { data: Provider; value: string }): JSX.Element | string => {
      const handleChange = async (isChecked: boolean) => {
        try {
          const input: Pick<Provider, 'id' | 'active'> = {
            id: data?.id,
            active: isChecked ? 1 : 0,
          };
          const res = await updateClientActivationBulk([input]);
          if (!res) throw new Error('Failed to update Client activation.');
        } catch (err) {
          createNotification('Error', Toast.Type.DANGER, err?.message || err);
          refetch();
        }
      };

      return <ActiveSwitch checked={!!data?.active} onChange={handleChange} />;
    };
    return ActiveCellComponent;
  }, [refetch]);

  return (
    <>
      <PageInfo>
        {filteredRows.length} / {totalCount || 0} Clients
      </PageInfo>
      <Filters
        name="clientsTable"
        onSubmit={handleSearch}
        onReset={(): void => setState((current: ClientTableState): ClientTableState => ({ ...current, search: '' }))}
        submitOnMount
        primary={({ values, onChange }): JSX.Element => {
          const { search } = values;
          return (
            <>
              <FormField
                prepend={<i className="sv sv-magnifier fs-4" />}
                name="search"
                onChange={onChange}
                onBlur={(): void => setState((current: ClientTableState): ClientTableState => ({ ...current, search }))}
                onKeyDown={onEnter((): void => setState((current: ClientTableState): ClientTableState => ({ ...current, search })))}
                placeholder="Search"
                value={search || ''}
                style={{ width: 300 }}
                condensed
              />
            </>
          );
        }}
        alternate={(): JSX.Element => {
          return (
            <>
              {/* Create Button */}
              <HasPermission name="allowCreateClient">
                <FormButton
                  icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
                  name="CREATE_CLIENT"
                  variant="outline-gray"
                  onClick={(): any =>
                    setState((current: ClientTableState): ClientTableState => ({ ...current, creating: true, editing: false }))
                  }
                >
                  <span>Add Client</span>
                </FormButton>
              </HasPermission>
              {/* Edit Bulk Button */}
              <FormButton
                icon={<i className="sv sv-layers {font-size:1.5rem;}" />}
                name="EDIT_CLIENT"
                variant="outline-gray"
                onClick={(): any =>
                  setState((current: ClientTableState): ClientTableState => ({ ...current, creating: false, editing: true }))
                }
                disabled={selected?.length === 0}
              >
                <span>Edit Client{selected.length > 1 ? 's' : ''}</span>
                {selected?.length > 1 && (
                  <Badge className="ms-2" pill bg="success">
                    {selected?.length}
                  </Badge>
                )}
              </FormButton>
              {/* Delete Button */}
              <ConfirmationButton
                icon={<i className="sv sv-trash2 {font-size:1.5rem;}" />}
                tooltip={`Delete Client${selected.length > 1 ? 's' : ''}`}
                name="DELETE_CLIENT"
                variant="outline-gray"
                disabled={!selected?.length}
                onConfirm={async (): Promise<void> => {
                  await deleteClientBulk(selected);
                  await onHide();
                }}
                options={{
                  confirmation: {
                    Body: {
                      message: `Deleting ${selected?.length} Client(s). Are you sure?`,
                    },
                  },
                }}
              />
            </>
          );
        }}
      />
      <VirtualTable
        name="provider"
        data={filteredRows}
        loading={loading}
        selected={selected}
        onLazyLoad={hasNextPage && rows.length < totalCount ? getMore : undefined}
        header={{
          name: 'Client',
          displayName: 'Short Name',
          contactPerson: 'Contact Person',
          primaryEmail: 'Email',
          primaryPhoneNumber: 'Phone',
          type: 'Type',
          active: 'Active',
        }}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              selected: _type === 'header' ? selected.length === context.rows.length : selected.includes(data?.id),
            }}
            className={getClasses(selected.includes(index) ? 'selected' : '')}
            onDoubleClick={(): void =>
              setState(
                (current: ClientTableState): ClientTableState => ({
                  ...current,
                  selected: Array.from(new Set([data?.id])),
                  editing: true,
                })
              )
            }
          >
            <SelectCell onClick={onSelect} />
            <DynamicCell
              selector="name"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 6)"
              sorting={makeSortable('name')}
            />
            <DynamicCell
              selector="displayName"
              placeholder="--"
              className="text-center"
              width="calc(100% / 6)"
              sorting={makeSortable('displayName')}
            />
            <DynamicCell
              selector="contactPerson"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 6)"
              sorting={makeSortable('contactPerson')}
            />
            <DynamicCell
              selector="primaryEmail"
              placeholder="--"
              className="text-center"
              width="calc(100% / 6)"
              sorting={makeSortable('primaryEmail')}
            />
            <DynamicCell
              selector="primaryPhoneNumber"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 6)"
              render={({ value }: { value: string }): string =>
                _type !== 'header' && value !== '--' ? parsePhoneNumber(value, ' ') : value
              }
              sorting={makeSortable('primaryPhoneNumber')}
            />
            <DynamicCell selector="type" placeholder="--" className="text-center" width="calc(100% / 6)" sorting={makeSortable('type')} />
            <DynamicCell
              selector="active"
              placeholder="--"
              className="text-center"
              width="calc(100% / 12)"
              sorting={makeSortable('active')}
              render={ActiveCell}
            />
          </VirtualTableRow>
        )}
      />
      <EditClientsModal
        show={!!editing || !!creating}
        data={
          editing ? (filteredRows || []).find((node: Provider): boolean => node.id === selected[0]) : creating ? { active: 0 } : undefined
        }
        onHide={onHide}
        options={{
          selected: filteredRows?.filter((row: Provider): boolean => selected?.includes(row.id)),
        }}
        loading={false}
        name="editClients"
      />
    </>
  );
};

export default ClientsTable;
