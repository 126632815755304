import { Button, Col, Row } from 'react-bootstrap';
import React, { useEffect, useRef } from 'react';

import { ContractedRatesFooter } from '../../pages/ContractedRates';
import ContractedRatesTable from '../ContractedRatesTable';
import EditModal from '../../components/EditModal/new';
import FormField from '../FormField';
import { createComponentState } from '@/state';
import { updateRateOnTrips } from '../../api/services/rates/updateRateOnTrips';
import { useGetCorrectRatesForTrips } from '../../api/services/rates/getCorrectRatesForTrips';
import useOnChange from '@/hooks/useOnChange';
import { useVirtualTable } from '../VirtualTable';

export type UseRatesReportModalState = {
  show?: boolean;
  search?: string;
  tripIds?: string[];
  selected?: any[];
  onSubmit?: (payload: UseRatesReportPayload) => Promise<void>;
  onHide?: () => void;
};
export type UseRatesReportPayload = { tripId: string; rateHistoryId: string }[];
const initUseRatesReportModalState: UseRatesReportModalState = { show: false, search: '', tripIds: [], selected: [] };
export const useRatesReportModal = createComponentState(initUseRatesReportModalState);

const RateReportModal = (): JSX.Element => {
  const [state, setState] = useRatesReportModal(({ state, setState }) => [state, setState]);
  const { show = false, search = '', tripIds = [], selected = [], onSubmit = async () => undefined, onHide = () => undefined } = state;

  const onChange = useOnChange(setState);
  const handleSubmit = async (): Promise<void> => {
    const payload = selectedRows.map((row) => ({ tripId: row?.id, rateHistoryId: row?.newRate?.id }));
    if (!payload?.length) return;
    await updateRateOnTrips(payload);
    onSubmit?.(payload);
    handleHide();
  };
  const handleHide = () => {
    setState(initUseRatesReportModalState);
    onHide?.();
  };
  const [{ data: rows = [], loading }, { fetch: getCorrectRatesForTrips }] = useGetCorrectRatesForTrips();
  const lastTripIds = useRef(null);

  const { selectedRows, onSelect, filteredRows } = useVirtualTable(setState, {
    selected,
    rows,
    search,
  });

  useEffect((): void => {
    if (!show || !tripIds?.length || JSON.stringify(tripIds) === JSON.stringify(lastTripIds.current)) return;
    lastTripIds.current = tripIds;
    getCorrectRatesForTrips(tripIds);
  }, [getCorrectRatesForTrips, show, tripIds]);

  return (
    <EditModal
      name="rateReport"
      show={show}
      onSubmit={handleSubmit}
      onHide={handleHide}
      size="xl"
      title="Rate Report"
      loading={loading}
      options={{
        Footer: (
          <Row className="justify-content-end pe-3 pt-2">
            <Col sm={2}>
              <Button variant="secondary" className="w-100" onClick={handleHide}>
                CANCEL
              </Button>
            </Col>
            <Col sm={2}>
              <Button name="SUBMIT" variant="primary" className="w-100" onClick={handleSubmit} disabled={!selected?.length}>
                Done
              </Button>
            </Col>
          </Row>
        ),
      }}
    >
      <FormField name="search" label="Search:" value={search || ''} onChange={onChange} options={{ group: { className: 'mt-0' } }} />
      <ContractedRatesTable data={filteredRows} selected={selected} onSelect={onSelect} loading={false} style={{ height: '60vh' }} />
      <ContractedRatesFooter rows={filteredRows} selected={selected} />
    </EditModal>
  );
};

export default RateReportModal;
