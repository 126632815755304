import {
  UpdateUserActivationBulkInput,
  UpdateUserActivationBulkResponse,
  UpdateUserActivationInput,
  UserActivationActionEnum,
} from '@/models/gen/graphql';

import { GraphApiResponse } from '@/api/core';
import { UpdateUserActivationBulkDocument } from '@/api/queries';
import { Validation } from '@/utils/validations';
import graphApi from '@/api';
import { handleError } from '@/utils/custom';

type UpdateUserActivationBulkGraphApiResponse = GraphApiResponse<typeof UpdateUserActivationBulkDocument>;

const title = 'Update User Activation';

const updateUserActivationValidator = new Validation.Validator({
  'userId!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val), defaultValue: '' }),
  'action!': (val: any): Validation.Validity => ({
    valid: Object.values(UserActivationActionEnum).includes(val),
    defaultValue: '',
  }),
});

const updateUserActivationBulkResponseSelector = (res: UpdateUserActivationBulkGraphApiResponse): UpdateUserActivationBulkResponse =>
  res?.updateUserActivationBulk;

const [runUpdateUserActivationBulk] = graphApi(UpdateUserActivationBulkDocument, {
  onError: (res: UpdateUserActivationBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const updateUserActivationBulk = async (activations: UpdateUserActivationInput[]): Promise<UpdateUserActivationBulkResponse> => {
  const users = activations.map((user: UpdateUserActivationInput): UpdateUserActivationInput => updateUserActivationValidator.create(user));
  const input: UpdateUserActivationBulkInput = {
    users,
  };
  const res = await runUpdateUserActivationBulk({ input });
  const data = updateUserActivationBulkResponseSelector(res);
  // if (data) createNotification('Success', Toast.Type.SUCCESS, title);
  return data;
};

export default updateUserActivationBulk;
