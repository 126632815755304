import './styles.scss';

import { Datetime, formatDollars, saveFile, uuid } from '../../utils';
import { InvoiceFormatEnum, RunPreviewInvoiceResponse } from '../../models/gen/graphql';
import {
  ParsedInvoiceInput,
  convertParsedInvoiceInputToInvoiceInput,
  parseGetInvoicePreviewResponse,
  useGetInvoicePreview,
} from '../../api/services/invoices';
import React, { useEffect, useMemo, useState } from 'react';
import { RouteProps, useNavigate, useParams } from 'react-router-dom';

import { Container } from 'react-bootstrap';
import InvoicePreviewFilters from './InvoicePreviewFilters';
import InvoicePreviewHeader from './InvoicePreviewHeader';
import InvoicePreviewSummary from './InvoicePreviewSummary';
import InvoicePreviewTable from './InvoicePreviewTable';
import InvoicePreviewTotals from './InvoicePreviewTotals';
import { LoadingBlur } from '../../components/LoadingSpinner';
import PageInfo from '../../components/PageInfo';
import { stringify } from '../../utils/objects';
import { useAbortable } from '@/hooks/useAbortable';
import useForm from '../../hooks/useForm';
import { useRunDownloadInvoice } from '../../api/services/invoices/runDownloadInvoice';
import { useRunSaveAndSendInvoice } from '../../api/services/invoices/runSaveAndSendInvoice';
import { useRunSaveAsInvoice } from '../../api/services/invoices/runSaveAsInvoice';
import { useRunSaveInvoice } from '../../api/services/invoices/runSaveInvoice';

export const initInvoicePreviewFilters: Partial<ParsedInvoiceInput> = {
  startDatetime: new Datetime(new Datetime().asDayjs().startOf('month')).toString(),
  endDatetime: new Datetime(new Datetime().asDayjs().endOf('month')).toString(),
  payerProviderId: undefined,
  iataAirlineCodes: undefined,
  airports: [],
  types: [],
  format: InvoiceFormatEnum.Standard,
  tripStatus: undefined,
};

const initPreview: ParsedInvoiceInput = {
  airports: [],
  due: new Datetime().endOf('day').toString(),
  emails: [],
  endDatetime: new Datetime().toString(),
  format: InvoiceFormatEnum.Standard,
  grandTotal: 0,
  headers: [],
  iataAirlineCodes: [],
  id: 0,
  invoiced: new Datetime().toString(),
  items: [],
  name: '',
  payerProviderId: '',
  startDatetime: new Datetime().toString(),
  total: 0,
  tripStatus: [],
  trips: [],
  types: [],
};
const InvoicePreview = (_props: RouteProps): JSX.Element => {
  const { id: invoiceIdString } = useParams();
  const invoiceId = parseInt(invoiceIdString) || 0;
  const navigate = useNavigate();

  const [preview, onChange, setPreview] = useForm(initPreview);
  const { format, grandTotal, total, trips, items } = preview;

  const [{ loading: saving }, { fetch: runSaveInvoice }] = useRunSaveInvoice();
  const [{ loading: savingAs }, { fetch: runSaveAsInvoice }] = useRunSaveAsInvoice();
  const [{ loading: downloading }, { fetch: runDownloadInvoice }] = useRunDownloadInvoice();
  const [{ loading: sending }, { fetch: runSaveAndSendInvoice }] = useRunSaveAndSendInvoice();
  const [{ data, loading: previewLoading }, { fetch: getInvoicePreview }] = useGetInvoicePreview();
  const loading = saving || savingAs || downloading || sending;
  const [submitLoading, setSubmitLoading] = useState<boolean>(saving || savingAs || downloading || sending);
  const { invoiceTerms = '', output, subtotals = [], summary } = data || {};

  //TODO: move logic to service
  const parseAndSetPreview = (response: RunPreviewInvoiceResponse): void => {
    const parsedResponse = parseGetInvoicePreviewResponse(response);
    const { output } = parsedResponse;
    const newPreview: ParsedInvoiceInput = Object.keys(initPreview).reduce((acc, key) => {
      let result = output?.[key] || output?.invoice?.[key] || initPreview[key];
      if (Array.isArray(result)) {
        result = Array.from(new Set(result));
      }
      acc[key] = result;
      return acc;
    }, {} as ParsedInvoiceInput);
    setPreview(newPreview);
  };

  const onSubmit = async (query: ParsedInvoiceInput): Promise<void> => {
    try {
      setSubmitLoading(true);
      const payload = convertParsedInvoiceInputToInvoiceInput({ ...preview, ...query });
      const response = await getInvoicePreview(payload);
      if (!response) return;
      parseAndSetPreview(response);
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitLoading(false);
    }
  };

  const saveAndLoadInvoice = async (method: 'save' | 'saveAs' | 'send') => {
    const fn = method === 'save' ? runSaveInvoice : method === 'saveAs' ? runSaveAsInvoice : runSaveAndSendInvoice;
    const res = await fn(convertParsedInvoiceInputToInvoiceInput(preview));
    if (res?.invoice?.id) navigate(`/invoices/${res?.invoice?.id}`);
  };
  const onSave = async (): Promise<void> => saveAndLoadInvoice('save');
  const onSend = async (): Promise<void> => saveAndLoadInvoice('send');
  const onSaveAs = async (): Promise<void> => saveAndLoadInvoice('saveAs');
  const onDownload = async (): Promise<void> => {
    await saveAndLoadInvoice(preview?.id === 0 ? 'saveAs' : 'save');
    const { url } = await runDownloadInvoice({
      ...convertParsedInvoiceInputToInvoiceInput(preview),
      id: preview?.id || invoiceId || parseInt(window.location.pathname.split('/').pop()) || null,
    });
    if (url) saveFile(url, preview?.name);
  };

  const loadExistingInvoice = async (invoiceId: number): Promise<void> => {
    const response = await getInvoicePreview(invoiceId);
    parseAndSetPreview(response);
  };

  const getPreview = async () => {
    try {
      const response = await getInvoicePreview(convertParsedInvoiceInputToInvoiceInput(preview));
      parseAndSetPreview(response);
    } catch (err) {
      console.error(err);
    }
  };

  const abortableGetPreview = useAbortable(getPreview, 5000);

  // This table has custom filtering logic, so we cannot use useVirtualTable to get this.
  // Instead we run our filter logic here.
  const filteredRows = useMemo((): any[] => {
    let lastRate;
    const result = [];
    trips.forEach((trip: any, index: number): any => {
      if (lastRate !== undefined && lastRate !== trip?.rate) {
        const subtotal: any = subtotals.find((sub: any): boolean => sub?.amount === lastRate);
        result.push({ id: uuid(), subtotal: <strong>SUBTOTAL</strong>, rate: <strong>{formatDollars(subtotal?.total || 0)}</strong> });
      }
      result.push({ ...trip, rate: formatDollars(trip.rate) });
      if (trips.length === index + 1) {
        const subtotal: any = subtotals.find((sub: any): boolean => sub?.amount === trip?.rate);
        result.push({ id: uuid(), subtotal: <strong>SUBTOTAL</strong>, rate: <strong>{formatDollars(subtotal?.total || 0)}</strong> });
      }
      lastRate = trip?.rate;
    });
    return result;
  }, [trips, subtotals]);

  const filters = useMemo(() => {
    if (stringify.compare(preview, initPreview)) return initInvoicePreviewFilters;
    //TODO: move logic to service (like above)
    const newFilters: Partial<ParsedInvoiceInput> = Object.keys(initInvoicePreviewFilters).reduce((acc, key) => {
      acc[key] = preview?.[key] || initInvoicePreviewFilters[key];
      return acc;
    }, {} as Partial<ParsedInvoiceInput>);
    return newFilters;
  }, [preview]);

  useEffect(() => {
    if (invoiceId === 0) return;
    loadExistingInvoice(invoiceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);

  return (
    <Container className="InvoicePreview page-container pt-4" fluid>
      <PageInfo>Total Trips: {trips.length}</PageInfo>
      {/* TODO: find and fix filters bug to render properly when needed */}
      {stringify.compare(filters, initInvoicePreviewFilters) && (
        <InvoicePreviewFilters onSubmit={onSubmit} value={initInvoicePreviewFilters} />
      )}
      {!stringify.compare(filters, initInvoicePreviewFilters) && <InvoicePreviewFilters onSubmit={onSubmit} value={filters} />}
      <LoadingBlur
        loading={(invoiceId !== 0 && filteredRows.length === 0) || loading || submitLoading}
        label={saving || savingAs || sending ? 'Saving...' : downloading ? 'Downloading...' : 'Loading Invoice...'}
      />
      {filteredRows?.length > 0 && (
        <InvoicePreviewHeader
          data={{
            ...preview,
            invoiceTerms,
            company: output?.company,
            payerProvider: output?.payerProvider,
            documents: output?.invoice?.documents,
          }}
          loading={loading}
          onChange={onChange}
          onDownload={onDownload}
          onSave={!invoiceId ? onSaveAs : onSave}
          onSend={onSend}
          onSaveAs={invoiceId ? onSaveAs : undefined}
        />
      )}
      {((invoiceId === 0 && !previewLoading) || filteredRows?.length > 0) && (
        <InvoicePreviewTable
          data={filteredRows}
          format={format}
          onChangeRate={async () => {
            abortableGetPreview();
          }}
        />
      )}
      {filteredRows?.length > 0 && (
        <>
          <InvoicePreviewTotals data={{ total, grandTotal }} onChange={onChange} items={items} loading={previewLoading} />
          {invoiceId === 0 && <InvoicePreviewSummary summary={summary} />}
        </>
      )}
    </Container>
  );
};

export default InvoicePreview;
