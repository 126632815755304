import './styles.scss';

import { Accordion, AccordionContext, Badge, Stack } from 'react-bootstrap';
import { ChatRoomType, Message, useChatState } from '@/hooks/useSocket/chat/useChatRooms';
import { Datetime, Validation, getClasses, queryInput } from '@/utils';
import React, { HTMLAttributes, useContext, useEffect, useMemo, useRef } from 'react';

import ActionsDropdown from '@/components/ActionsDropdown';
import ChatMessageAvatar from '../../ChatMessage/ChatMessageAvatar';
import ConfirmationButton from '@/components/ConfirmationButton';
import { DATETIME_CHAT_FORMAT } from '@/constants';
import searchTrips from '@/api/services/trips/searchTrips';
import useChatMethods from '@/hooks/useSocket/chat/useChatMethods';
import { useEditTripsModal } from '@/components/EditTripsModal';

type CommonChatListHeaderProps = {
  roomId?: string;
  roomType?: ChatRoomType;
  name?: string;
  displayImageUrl?: string;
  flightNumber?: number;
  servicerIataAirlineCode?: string;
  latestMessage?: Message;
  unread?: number;
  scheduledTime?: string;
};

type ChatListHeaderProps = HTMLAttributes<HTMLDivElement> &
  CommonChatListHeaderProps & {
    eventKey: string;
  };

const ChatListHeader = ({
  roomId,
  unread,
  name,
  latestMessage,
  scheduledTime,
  servicerIataAirlineCode,
  displayImageUrl,
  eventKey,
  roomType,
  ...props
}: ChatListHeaderProps) => {
  const { activeEventKey } = useContext(AccordionContext);
  const headerRef = useRef(null);
  const isOpen = activeEventKey === eventKey;

  useEffect(() => {
    if (!isOpen) return;
    const scrollTimer = setTimeout(() => headerRef.current.scrollIntoView({ behavior: 'instant' }), 250);
    return () => clearTimeout(scrollTimer);
  }, [isOpen]);

  return (
    <div {...props} className={getClasses('ChatList-Header', props?.className)} ref={headerRef}>
      <Accordion.Header as="div">
        {!isOpen ? (
          <ChatListHeader.NotActive
            unread={unread}
            name={name}
            displayImageUrl={displayImageUrl}
            scheduledTime={scheduledTime}
            latestMessage={latestMessage}
            servicerIataAirlineCode={servicerIataAirlineCode}
          />
        ) : (
          <ChatListHeader.Active
            roomType={roomType}
            roomId={roomId}
            name={name}
            displayImageUrl={displayImageUrl}
            servicerIataAirlineCode={servicerIataAirlineCode}
            scheduledTime={scheduledTime}
          />
        )}
      </Accordion.Header>
    </div>
  );
};

type ChatListHeaderNotActiveProps = HTMLAttributes<HTMLDivElement> & CommonChatListHeaderProps;
const ChatListHeaderNotActive = ({
  unread,
  name,
  displayImageUrl,
  servicerIataAirlineCode,
  scheduledTime,
  latestMessage,
  ...props
}: ChatListHeaderNotActiveProps) => {
  return (
    <div className={getClasses('ChatList-Header-NotActive', 'ps-3 w-100 d-flex gap-4', props?.className)}>
      <div>
        <ChatMessageAvatar url={displayImageUrl} size="sm" />
      </div>
      <div className="w-100 {max-width:46%;}">
        {(latestMessage?.type || '').startsWith('image/') ? (
          <ChatListHeader.Title title={name} imageContent={latestMessage?.content} imageType={latestMessage?.type} />
        ) : (
          <ChatListHeader.Title title={name} subtitle={latestMessage?.content} />
        )}
      </div>
      <div className="d-flex w-100 justify-content-center align-items-end flex-column">
        <ChatListHeader.Info unread={unread} time={scheduledTime} airport={servicerIataAirlineCode} />
      </div>
    </div>
  );
};

type ChatListHeaderActiveProps = HTMLAttributes<HTMLDivElement> & CommonChatListHeaderProps;
const ChatListHeaderActive = ({
  roomId,
  name,
  displayImageUrl,
  servicerIataAirlineCode,
  scheduledTime,
  roomType,
  ...props
}: ChatListHeaderActiveProps) => {
  const timestamp = useMemo(() => new Datetime().setAsUTC(scheduledTime).format(DATETIME_CHAT_FORMAT), [scheduledTime]);
  return (
    <Stack
      direction="horizontal"
      gap={4}
      className={getClasses('ChatList-Header-Active', 'w-100 pb-3 border-secondary-subtle border-bottom border-3', props?.className)}
    >
      <ChatMessageAvatar url={displayImageUrl} size="sm" />
      <ChatListHeader.Title title={name} subtitle={`${timestamp}`} />
      <div className="ms-auto" />
      <ChatListHeader.End roomId={roomId} roomType={roomType} />
      <ChatListHeader.Actions roomId={roomId} roomType={roomType} />
    </Stack>
  );
};

type ChatListHeaderMessageCenterProps = HTMLAttributes<HTMLDivElement> & CommonChatListHeaderProps;
const ChatListHeaderMessageCenter = ({
  unread,
  name,
  displayImageUrl,
  servicerIataAirlineCode,
  scheduledTime,
  latestMessage,
  ...props
}: ChatListHeaderMessageCenterProps) => {
  return (
    <div className={getClasses('ChatList-Header-Open', 'w-100 d-flex gap-4', props?.className)}>
      <div className="flex-shrink-0">
        <ChatMessageAvatar url={displayImageUrl} size="sm" />
      </div>
      <div className="w-100 {max-width:50%;}">
        {(latestMessage?.type || '').startsWith('image/') ? (
          <ChatListHeader.MesageCenterTitle
            name={name}
            time={scheduledTime}
            airport={servicerIataAirlineCode}
            imageContent={latestMessage?.content}
            imageType={latestMessage?.type}
          />
        ) : (
          <ChatListHeader.MesageCenterTitle
            name={name}
            time={scheduledTime}
            airport={servicerIataAirlineCode}
            latestMessage={latestMessage?.content}
          />
        )}
      </div>
      <div className="d-flex w-100 justify-content-center align-items-center flex-column">
        {unread > 0 && (
          <Badge bg="secondary" className="text-dark align-self-end rounded-circle fs-6">
            {unread}
          </Badge>
        )}
      </div>
    </div>
  );
};

type ChatListHeaderTitleProps = HTMLAttributes<HTMLDivElement> & {
  title: string;
  subtitle?: string;
  imageType?: string;
  imageContent?: string;
};
const ChatListHeaderTitle = ({ title, subtitle, imageType, imageContent, ...props }: ChatListHeaderTitleProps) => {
  return (
    <div {...props} className={getClasses('ChatList-Title', props?.className)}>
      <p className="fs-5 fw-bold">{title}</p>
      {subtitle && <p className="ChatList-Subtitle fs-6">{subtitle}</p>}
      {imageType && imageContent && (
        <span>
          <img className="{max-width:24px;height:24px;}" src={`data:${imageType};charset=utf-8;base64,${imageContent}`} alt="Avatar" />
        </span>
      )}
    </div>
  );
};

type ChatListMessageCenterTitleProps = HTMLAttributes<HTMLDivElement> & {
  name?: string;
  time?: string;
  airport?: string;
  latestMessage?: string;
  imageType?: string;
  imageContent?: string;
};
const ChatListMessageCenterTitle = ({
  name,
  time,
  airport,
  latestMessage,
  imageType,
  imageContent,
  ...props
}: ChatListMessageCenterTitleProps) => {
  const timestamp = useMemo(() => new Datetime().setAsUTC(time).format(DATETIME_CHAT_FORMAT), [time]);
  return (
    <div {...props} className={getClasses('ChatList-Title', props?.className)}>
      <div>
        <p>
          <span className="fw-bold">{name}</span> <span className="text-muted">| {timestamp}</span>
        </p>
        {latestMessage && <p className="ChatList-Subtitle fs-6">{latestMessage}</p>}
        {imageType && imageContent && (
          <span>
            <img className="{max-width:24px;height:24px;}" src={`data:${imageType};charset=utf-8;base64,${imageContent}`} alt="Avatar" />
          </span>
        )}
      </div>
    </div>
  );
};

type ChatListHeaderEndProps = HTMLAttributes<HTMLDivElement> & {
  roomId?: string;
  roomType: ChatRoomType;
};
const ChatListHeaderEnd = ({ roomId, roomType, ...props }: ChatListHeaderEndProps) => {
  const { sendMessage } = useChatMethods();
  const rooms = useChatState(({ state: { rooms } }) => rooms[roomType]);
  return (
    <div {...props} className={getClasses('ChatList-End', props?.className)}>
      <ConfirmationButton
        as="div"
        options={{
          confirmation: {
            Header: {
              title: 'End Chat',
            },
            Body: {
              message: 'Are you sure you want to end this chat? Has the issue been resolved?',
            },
          },
        }}
        onConfirm={() => {
          sendMessage({
            ...(rooms?.[roomId] && {
              trip_id: rooms[roomId]?.trip?.tripId || null,
              trip_scheduled: rooms[roomId]?.trip?.tripScheduled || null,
              flight_number: rooms[roomId]?.trip?.tripFlightNumber || null,
              servicer_iata_airline_code: rooms[roomId]?.trip?.servicerIataAirlineCode || null,
              room_display_name: rooms[roomId]?.displayName || null,
              room_display_image_url: rooms[roomId]?.displayImageUrl || null,
              loop_name: rooms[roomId]?.latestMessage?.loop_name || null,
            }),
            room: roomId,
            content: 'Thank you for messaging with us today. Thanks for your business and safe travels.',
            content_type: 'text/plain',
          });
        }}
        variant="primary"
        size="lg"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          alert('Ending chat');
        }}
      >
        End Chat
      </ConfirmationButton>
    </div>
  );
};

type ChatListHeaderActionsProps = HTMLAttributes<HTMLDivElement> &
  CommonChatListHeaderProps & {
    roomType?: ChatRoomType;
  };
const ChatListHeaderActions = ({ roomId, roomType, ...props }: ChatListHeaderActionsProps) => {
  const rooms = useChatState(({ state: { rooms } }) => rooms);
  const showEditTripsModal = useEditTripsModal(({ setState }) => setState);
  return (
    <div {...props} className={getClasses('ChatList-Actions', props?.className)}>
      <ActionsDropdown
        items={[
          {
            key: '1',
            label: 'Open Trip',
            disabled: !Validation.isValidUUID(rooms?.[roomType || ChatRoomType.CURRENT_CHAT]?.[roomId]?.trip?.tripId),
            onClick: async ({ domEvent }) => {
              domEvent.stopPropagation();
              const {
                rows: [trip],
              } = await searchTrips([
                {
                  id: queryInput(rooms?.[roomType || ChatRoomType.CURRENT_CHAT]?.[roomId]?.trip?.tripId),
                },
              ]);
              showEditTripsModal({
                show: true,
                trip,
                selected: [trip],
              });
            },
            icon: <i className="sv sv-earth" />,
          },
        ]}
        onClick={() => {}}
      />
    </div>
  );
};

type ChatListHeaderInfoProps = HTMLAttributes<HTMLDivElement> & {
  time?: string;
  airport?: string;
  unread?: number;
};
const ChatListHeaderInfo = ({ airport, time, unread, ...props }: ChatListHeaderInfoProps) => {
  const timestamp = useMemo(() => new Datetime().setAsUTC(time).format(DATETIME_CHAT_FORMAT), [time]);
  return (
    <div {...props} className={getClasses('ChatList-Info', props?.className)}>
      <div className="d-flex align-items-center justify-content-center flex-column">
        {airport && time && (
          <p>
            {airport} - {timestamp}
          </p>
        )}
        {unread > 0 && (
          <Badge bg="secondary" className="text-dark align-self-end rounded-circle fs-6">
            {unread}
          </Badge>
        )}
      </div>
    </div>
  );
};

ChatListHeader.Active = ChatListHeaderActive;
ChatListHeader.NotActive = ChatListHeaderNotActive;
ChatListHeader.MessageCenter = ChatListHeaderMessageCenter;
ChatListHeader.Actions = ChatListHeaderActions;
ChatListHeader.End = ChatListHeaderEnd;
ChatListHeader.Info = ChatListHeaderInfo;
ChatListHeader.Title = ChatListHeaderTitle;
ChatListHeader.MesageCenterTitle = ChatListMessageCenterTitle;
export default ChatListHeader;
