import { UpdateRateOnTripInput, UpdateRateOnTripResponse, UpdateRateOnTripsInput } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { GraphApiResponse } from '../../core';
import { Toast } from '@/models';
import { UpdateRateOnTripsDocument } from '../../queries';
import graphApi from '../..';

type UpdateRateOnTripsGraphApiResponse = GraphApiResponse<typeof UpdateRateOnTripsDocument>;

const updateRatesOnTripsResponseSelector = (res: UpdateRateOnTripsGraphApiResponse): UpdateRateOnTripResponse[] =>
  !res.errors ? (res?.updateRateOnTrips?.output as UpdateRateOnTripResponse[]) : undefined;

const [runUpdateRateOnTrips] = graphApi(UpdateRateOnTripsDocument, {
  onError: (res: UpdateRateOnTripsGraphApiResponse): void => handleError(res, { notification: { title: 'Update Rate On Trip(s)' } }),
});

export const updateRateOnTrips = async (updates: UpdateRateOnTripInput[]): Promise<UpdateRateOnTripResponse[]> => {
  const input: UpdateRateOnTripsInput = {
    input: updates,
  };
  const res = await runUpdateRateOnTrips({
    input,
  });

  const selected = updateRatesOnTripsResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, 'Update Rate on Trips');

  return selected;
};

export default updateRateOnTrips;
